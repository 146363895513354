import React, {
  useState,
  KeyboardEvent,
  useRef,
  ChangeEvent,
  useEffect,
} from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

import ChatBoxUser from "./components/chat-box-user";
import ChatBoxAi from "./components/chat-box-ai";
import ChatBoxLoader from "./components/chat-box-loader";

import { useConversation } from "shared/modules/capitalmind/hooks/conversation";
import { useFileUpload } from "shared/modules/capitalmind/hooks/file-upload";
import Placeholder from "./components/placeholder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclip,
  faGear,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import FileModal from "../context-modal/file-modal";

import CapitalMindLogo from "theme/assets/images/capital-mind-logo.png";
import ChatBoxFile from "./components/chat-box-file";
import PreferenceModal from "../preference/preference-modal";

const FILE_UPLOAD_LIMIT = 25;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
`;

const StyledBanner = styled.div`
  background-color: #35cc62;
  border-radius: 0px 0px 16px 16px;
  justify-content: center;
  display: flex;
  padding: 16px;
  margin: 0px 0px 8px 0px;
  flex-direction: row;
`;

const StyledFooter = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 0.5px solid #d9dfe9;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  z-index;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  padding: 12px 0px 12px 16px;
  cursor: pointer;
`;

const StyledIconContainerLeft = styled.div`
  padding: 12px 16px 12px 0px;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #eff2f6;
  width: 100%;
  max-width: 812px;
`;

const StyledFooterText = styled.span`
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  text-align: center;
  color: #a0aabd;
`;

const StyledTextInput = styled.input`
  font-family: Poppins;
  background-color: transparent;
  border: 0px;
  width: 100%;
  outline: none;
  font-size: 16px;
`;

const StyledChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 82vh;
  overflow: scroll;
  margin-bottom: 120px;
`;

const IconContainerLeft = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const IconContainerRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

function ChatModule(): JSX.Element {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const { fileUploadHandler, isLoading: isFileUploading } = useFileUpload({
    source: "capitalmind",
  });

  const {
    handleConversation,
    conversations,
    isLoading,
    total,
    handleFileUpload,
  } = useConversation("capitalmind");
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false);

  const onKeyDown = (keyEvent: KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === "Enter" && query) {
      handleConversation(query);
      setQuery("");
    }
  };

  const handleFileLimitReached = () => {
    toast.error(
      `Only ${FILE_UPLOAD_LIMIT} files are allowed at a time. Please try again.`
    );
  };

  const onClickHandler = () => {
    if (query && !isFileUploading && !isLoading) {
      handleConversation(query);
      setQuery("");
    }
  };

  const handleClick = () => {
    if (hiddenFileInput?.current && !isLoading && !isFileUploading) {
      hiddenFileInput.current.click();
    }
  };

  const handleFile = (files: File[]) => {
    if (!isLoading && !isFileUploading) {
      fileUploadHandler({ files, source: "capitalmind" });
      files.forEach((file) => {
        handleFileUpload(`Document upload : ${file.name}.`);
      });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const targetFiles = target.files as FileList;
    const files = [];
    const fileLength = targetFiles?.length as number;
    if (fileLength && fileLength <= FILE_UPLOAD_LIMIT) {
      for (let i = 0; i < fileLength; i++) {
        files.push(targetFiles[i]);
      }
    } else {
      handleFileLimitReached();
    }
    if (files.length > 0) {
      handleFile(files);
    }
  };

  useEffect(() => {
    if (conversations.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [conversations.length]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePreferenceModal = () => {
    setIsPreferenceModalOpen(false);
  };

  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <FileModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <PreferenceModal
        isModalOpen={isPreferenceModalOpen}
        closeModal={closePreferenceModal}
      />
      <StyledBanner>
        <IconContainerLeft onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon={faFolderOpen} size="lg" color={"#ffffff"} />
        </IconContainerLeft>
        <LogoContainer>
          <img src={CapitalMindLogo} style={{ height: 36 }} alt="CM" />
        </LogoContainer>
        <IconContainerRight onClick={() => setIsPreferenceModalOpen(true)}>
          <FontAwesomeIcon icon={faGear} size="lg" color={"#ffffff"} />
        </IconContainerRight>
      </StyledBanner>
      <StyledChatContainer>
        {!total && !isLoading && <Placeholder />}
        {conversations.map((conversation, index) => {
          return (
            <div key={index}>
              {conversation.type === "ai" ? (
                <ChatBoxAi text={conversation.text} />
              ) : conversation.type === "user" ? (
                <ChatBoxUser text={conversation.text} />
              ) : conversation.type === "file" ? (
                <ChatBoxFile
                  isFileUploading={isFileUploading}
                  text={conversation.text}
                />
              ) : (
                <></>
              )}
            </div>
          );
        })}
        {isLoading && <ChatBoxLoader />}
        <div ref={ref} />
      </StyledChatContainer>
      <StyledFooter>
        <StyledInputWrapper>
          <StyledIconContainerLeft onClick={handleClick}>
            <FontAwesomeIcon
              icon={faPaperclip}
              size="xl"
              color={isFileUploading || isLoading ? "#a0aabd" : "#35cc62"}
            />
            <input
              ref={hiddenFileInput}
              onChange={handleChange}
              hidden
              type="file"
              accept=".pdf"
              multiple
            />
          </StyledIconContainerLeft>
          <StyledInputContainer>
            <StyledTextInput
              placeholder="You're talking to 'Nearly Deepak Shenoy'."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={onKeyDown}
              disabled={isLoading}
            />
          </StyledInputContainer>
          <StyledIconContainer onClick={onClickHandler}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              size="xl"
              color={
                isFileUploading || isLoading || !query ? "#a0aabd" : "#35cc62"
              }
            />
          </StyledIconContainer>
        </StyledInputWrapper>
        <StyledFooterText>
          Powered by <span style={{ color: "#35cc62" }}>Mool.ai</span>. This
          tool is meant for education and it is not by any means financial
          advice.
        </StyledFooterText>
      </StyledFooter>
    </Container>
  );
}

export default ChatModule;
