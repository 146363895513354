import React from "react";
import styled from "styled-components";
import DeepakShenoy from "theme/assets/images/deepak-shenoy.png";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 8px 0px;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px 16px 16px 0px;
  background-color: #f7f8fc;
  width: 40%;
`;

const StyledText = styled.span`
  color: #4b5466;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledImageContainer = styled.div`
  background-color: #a0aabd;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  align-self: flex-end;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ChatBoxAi({ text }: { text: string }): JSX.Element {
  return (
    <StyledContainer>
      <StyledImageContainer>
        <img
          src={DeepakShenoy}
          style={{ height: 24, width: 24, borderRadius: 12 }}
          alt="DS"
        />
      </StyledImageContainer>
      <StyledUserChatBox>
        <StyledText>{text}</StyledText>
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default ChatBoxAi;
