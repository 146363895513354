import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0px;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px 16px 0px 16px;
  background-color: #00c177;
  width: 40%;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
`;

function ChatBoxUser({ text }: { text: string }): JSX.Element {
  return (
    <StyledContainer>
      <StyledUserChatBox>
        <StyledText>{text}</StyledText>
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default ChatBoxUser;
