import React from "react";
import styled from "styled-components";
import DeepakShenoy from "theme/assets/images/deepak-shenoy.png";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  max-width: 812px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #d9dfe9;
`;

const StyledHeaderText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  color: #4b5466;
  margin-top: 8px;
  text-align: center;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: column;
`;

const StyledImageContainer = styled.div`
  margin: 8px;
`;

function Placeholder(): JSX.Element {
  return (
    <Container>
      <StyledWrapper>
        <StyledHeaderContainer>
          <StyledHeaderText
            style={{ fontSize: 32, fontWeight: 600, marginRight: 8 }}
          >
            👋
          </StyledHeaderText>
          <StyledHeaderText style={{ fontSize: 24, fontWeight: 600 }}>
            Welcome to CapitalMind AI!
          </StyledHeaderText>
          <StyledImageContainer>
            <img
              src={DeepakShenoy}
              style={{ height: 96, width: 96, borderRadius: 24 }}
              alt="DS"
            />
          </StyledImageContainer>
          <StyledHeaderText style={{ fontSize: 16, fontWeight: 600 }}>
            "Nearly Deepak Shenoy"
          </StyledHeaderText>
          <StyledHeaderText
            style={{ fontSize: 12, fontWeight: 600, marginTop: 12 }}
          >
            can help you with:
          </StyledHeaderText>
        </StyledHeaderContainer>
        <StyledHeaderText>🤔 Answers to questions on finance</StyledHeaderText>
        <StyledHeaderText>
          ✌️ Discussing your finances and progress
        </StyledHeaderText>
        <StyledHeaderText>💸 Learning about money</StyledHeaderText>
      </StyledWrapper>
    </Container>
  );
}

export default Placeholder;
