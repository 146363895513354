import { useEffect, useState } from "react";

import { useChatText } from "./chat-text";

export interface Conversation {
  type: "user" | "ai" | "file";
  text: string;
}

interface Return {
  conversations: Conversation[];
  handleConversation: (query: string) => void;
  isLoading: boolean;
  total: number;
  handleFileUpload: (query: string) => void;
}

export function useConversation(repo?: string): Return {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const { handleChat, isLoading, text, threadId } = useChatText(repo);

  const handleConversation = (query: string) => {
    setConversations((prev) => [...prev, { type: "user", text: query }]);
    handleChat({ query, threadId });
  };

  const handleFileUpload = (query: string) => {
    setConversations((prev) => [...prev, { type: "file", text: query }]);
  };

  useEffect(() => {
    if (!isLoading && text) {
      setConversations((prev) => [...prev, { type: "ai", text }]);
    }
  }, [text, isLoading]);

  const total = conversations.length;

  return {
    conversations,
    handleConversation,
    isLoading,
    total,
    handleFileUpload,
  };
}
