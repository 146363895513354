import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import ChatLoaderLottie from "theme/assets/lotties/chat-loader-lottie.json";

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 8px 0px;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px 16px 16px 0px;
  background-color: #f7f8fc;
`;

function ChatBoxLoader(): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ChatLoaderLottie,
  };
  return (
    <StyledContainer>
      <StyledUserChatBox>
        <Lottie options={defaultOptions} height={"80px"} />
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default ChatBoxLoader;
