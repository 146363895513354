import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { useLlmPreference } from "shared/modules/capitalmind/hooks/llm-preferences";
import { styled } from "styled-components";
import useAutosizeTextArea from "../../components/app-text-area/hooks";

const StyledLlmContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLlmButton = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  margin: 0px 16px;
  padding: 12px;
  border: 2px solid;
  border-color: ${(props) => (props.isSelected ? "#35cc62" : "#a0aabd")};
  border-radius: 4px;
  cursor: pointer;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #35cc62;
  width: 200px;
  justify-content: center;
  font-weight: 600;
  line-height: 26px;
  color: white;
`;

const StyledPreferenceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 32px 0px;
`;

const StyledPreferenceItem = styled.div`
  margin-right: 48px;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: black;
`;

const StyledLabelText = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const StyledInputContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: ${(props) => (props?.isFocused ? "#ffffff" : "#eff2f6")};
  border: ${(props) =>
    props?.isFocused ? "1px solid #eff2f6" : "1px solid transparent"};
  width: 70%;
  flex-wrap: wrap;
  overflow-wrap: break-word;
`;

const StyledTextInput = styled.textarea`
  font-family: Poppins;
  background-color: transparent;
  border: 0px;
  width: 100%;
  outline: none;
  font-size: 16px;
  resize: none;
  box-sizing: "border-box";
  overflow: hidden;
`;

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
}

const customStyles = {
  content: {
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -33%)",
  },
};

function PreferenceModal({ isModalOpen, closeModal }: Props): JSX.Element {
  const {
    llmPreferences,
    selectedLlmPreference,
    updateLlmPreference,
    prompt: globalPrompt,
    updatePrompt,
  } = useLlmPreference();

  const [prompt, setPrompt] = useState(globalPrompt);
  const [isFocused, setIsFocused] = useState(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, prompt);

  const handleSave = () => {
    updatePrompt(prompt);
    closeModal();
  };

  const handleOnBlur = () => {
    if (!prompt) {
      setPrompt(globalPrompt);
    }
    setIsFocused(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <StyledPreferenceItemContainer>
        <StyledPreferenceItem>
          <StyledLabelText>LLM Model :</StyledLabelText>
        </StyledPreferenceItem>
        <StyledLlmContainer>
          {llmPreferences.map((llm) => (
            <StyledLlmButton
              key={llm.key}
              isSelected={selectedLlmPreference?.key === llm.key}
              onClick={() => updateLlmPreference(llm.key)}
            >
              <StyledText> {llm.label}</StyledText>
            </StyledLlmButton>
          ))}
        </StyledLlmContainer>
      </StyledPreferenceItemContainer>

      <StyledPreferenceItemContainer>
        <StyledPreferenceItem>
          <StyledLabelText>Prompt Template:</StyledLabelText>
        </StyledPreferenceItem>
        <StyledInputContainer isFocused={isFocused}>
          <StyledTextInput
            placeholder="Set a prompt persona for your llm"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={handleOnBlur}
            ref={textAreaRef}
            rows={4}
          />
        </StyledInputContainer>
      </StyledPreferenceItemContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <StyledButton onClick={handleSave}>Save</StyledButton>
      </div>
    </Modal>
  );
}

export default PreferenceModal;
