import { useContext } from "react";
import { mergeState, createStateContext } from "shared/utils/state-utils";
import {
  DEFAULT_LLM_PROMPT,
  DEFAULT_LLM_PROMPT_DALBERG,
  DEMO_LLM_PROMPT,
  MOOL_LLM_PROMPT,
} from "shared/config";

export type GlobalState = {
  llmModel: string;
  prompt: string;
  prompt_dalberg: string;
  demo_prompt: string;
  mool_prompt: string;
};

export const InitialState: GlobalState = {
  llmModel: "open-ai-gpt",
  prompt: DEFAULT_LLM_PROMPT,
  prompt_dalberg: DEFAULT_LLM_PROMPT_DALBERG,
  demo_prompt: DEMO_LLM_PROMPT,
  mool_prompt: MOOL_LLM_PROMPT,
};

export const GlobalStateContext = createStateContext<GlobalState>(InitialState);

export function useGlobalState(): {
  globalState: GlobalState;
  setGlobalState: (newState: Partial<GlobalState>) => void;
} {
  const { state, setState } = useContext(GlobalStateContext);

  const setGlobalState = (newState: Partial<GlobalState>): void => {
    setState(mergeState<GlobalState>(state, newState));
  };

  return { globalState: state, setGlobalState };
}
