import {
  useChatTextQuery,
  ChatResponse,
  RequestPayload,
} from "shared/api/v1/query/chat/post";
import { useGlobalState } from "shared/global-state";

interface ReturnType {
  isLoading: boolean;
  text: string;
  handleChat: (payload: RequestPayload) => void;
  threadId: string;
}

export function useChatText(repo?: string): ReturnType {
  const { mutate, isLoading, data } = useChatTextQuery();
  const { globalState } = useGlobalState();
  const { llmModel, prompt, prompt_dalberg } = globalState;

  const llm_prompt = repo === "dalberg" ? prompt_dalberg : prompt;

  const responseData = data as ChatResponse;

  const handleChat = (payload: RequestPayload) => {
    mutate({
      ...payload,
      modelName: payload?.modelName || llmModel,
      llmPrompt: llm_prompt,
      namespace: repo,
    });
  };

  return {
    isLoading,
    text: responseData?.data?.text,
    handleChat,
    threadId: responseData?.data?.threadId,
  };
}
