import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "../routes";
import { QueryClientProvider } from "react-query";
import { GlobalStateContext, InitialState } from "shared/global-state";
import queryClient from "shared/network";

import "react-toastify/dist/ReactToastify.css";

function App(): JSX.Element {
  const [globalState, setGlobalState] = useState(InitialState);
  return (
    <React.StrictMode>
      <GlobalStateContext.Provider
        value={{
          state: globalState,
          setState: setGlobalState,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </GlobalStateContext.Provider>
    </React.StrictMode>
  );
}

export default App;
